// i18next-extract-mark-ns-start bizum-for-shopify
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import scan_to_pay from 'images/scan_to_pay.png';

//Images
import shopify_bizum from 'images/shopify_bizum.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index2 from 'images/best_payment_gateway_rates.svg';

import React from 'react';
import {List, OrderedList} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {Bold} from 'components/StickyBanner';
import {SignUpLink} from 'components/links/Other';
import {
  BizumEmpresasLink,
  InternalPageLink,
  MoneiPayLink,
  MoneiPayQrLink,
  MoneiPayRestaurantsLink
} from 'components/links/Pages';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {MainTitleImg} from 'components/combo-pages/MainTitleImg';
import styled from 'styled-components';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const SellingPage: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        path="bizum-for-shopify"
        title={t('Bizum for Shopify')}
        description={t(
          'Grow your e-commerce business with MONEI’s payment gateway: Dynamic pricing. More payment methods. E-commerce platform or custom website integration. Join » '
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Bizum for Shopify</Trans>
              </SectionHeader>
              <Trans parent="p">
                Increase conversions by offering Bizum’s mobile payment solution to your customers
                as a payment method at checkout. MONEI is the first payment gateway to support Bizum
                for Shopify so you can use it as a direct way to send and receive money.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg src={shopify_bizum} alt="MONEI Bizum" title="MONEI Bizum" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              className="hide-on-mobile"
            />
            <div>
              <SectionHeader>
                <Trans>Benefits of accepting Bizum payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding Bizum to your Shopify store has many advantages for your e-commerce business:
              </Trans>
              <List>
                <Trans parent="li">
                  <Bold>Reach more customers.</Bold> Bizum has over a dozen million users (and
                  growing) in Spain. In 2021, e-commerce payments through Bizum hit 85 million
                  euros.
                </Trans>
                <Trans parent="li">
                  <Bold>Immediate transactions.</Bold> With Bizum, money flows from your customer's
                  account to your business bank account in under five seconds.
                </Trans>
                <Trans parent="li">
                  <Bold>No chargebacks.</Bold> Bizum doesn’t allow chargebacks, protecting your
                  e-commerce site against fraudulent activity.
                </Trans>
                <Trans parent="li">
                  <Bold>Safe and secure.</Bold> Bizum complies with{' '}
                  <BlogLink slug="psd2">PSD2 and SCA</BlogLink> regulations.
                </Trans>
                <Trans parent="li">
                  <Bold>Control over availability.</Bold> Enable Bizum payments for specific
                  transactions — for example, for a maximum purchase amount.
                </Trans>
                <Trans parent="li">
                  <Bold>Supported by all central Spanish banks.</Bold> Bizum partners with
                  CaixaBank, Santander, Sabadell, BBVA, Bankia, Kutxabank, Caja Rural, Unicaja, and
                  ABANCA.
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Accept Bizum payments and many other Shopify payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding Bizum to your Shopify store is a good start, but it shouldn’t be the only
                payment method you accept. Consumers are looking for many payment methods at
                checkout.
              </Trans>
              <Trans parent="p">Connect with MONEI to accept:</Trans>
              <List>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/credit-cards">
                    Credit and debit cards
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <BlogLink slug="digital-wallet-ecommerce">Digital wallets</BlogLink> like{' '}
                  <InternalPageLink slug="payment-methods/paypal">Paypal</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <BlogLink slug="local-payment-methods">Local payment methods</BlogLink> like{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>{' '}
                  (Portugal),{' '}
                  <InternalPageLink slug="payment-methods/sepa-direct-debit">
                    SEPA Direct Debit
                  </InternalPageLink>{' '}
                  (EU)
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section centered>
            <SectionImage
              src={scan_to_pay}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              className="hide-on-mobile"
              width={321}
              height={600}
            />
            <div>
              <SectionHeader>
                <Trans>Looking to accept Bizum payments in person?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accept <BizumEmpresasLink>Bizum payments anywhere</BizumEmpresasLink> (in-store, on
                the go, at events, or in your{' '}
                <MoneiPayRestaurantsLink>restaurant</MoneiPayRestaurantsLink>) with{' '}
                <MoneiPayLink>MONEI Pay</MoneiPayLink>. Use digital{' '}
                <MoneiPayQrLink>QR codes</MoneiPayQrLink> to take Bizum payments from your
                smartphone or tablet.
              </Trans>
              <Trans parent="p">
                All your customers have to do is scan the QR code to pay with their mobile device
                and complete the transaction with the tap of a button. It’s contactless, secure, and
                speeds up the checkout process.
              </Trans>
              <Trans parent="p">Power your omnichannel payments with MONEI.</Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get Started</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <SetupIntegration>
        <SectionHeader underline tagName="h3">
          <Trans>How to configure Bizum in Shopify</Trans>
        </SectionHeader>
        <Trans parent="p">
          Connect your Shopify store with MONEI to add Bizum as a payment method in just a few short
          steps:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/articles/360018047698">
              Contact support
            </AnchorLink>{' '}
            to configure Bizum in your account
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/">
              Connect MONEI
            </AnchorLink>{' '}
            to your Shopify store
          </Trans>
          <Trans parent="li">
            Learn how to{' '}
            <AnchorLink href="https://support.monei.com/hc/categories/360003378957">
              configure all payment methods
            </AnchorLink>
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "bizum-for-shopify"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
